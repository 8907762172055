
import React from "react";
import "./style_Portret.css";
import { stylesTimers } from "../services/timers";

function Portret() {
    const [imageLoadEffect, setImageLoadEffect] = React.useState(false)

    React.useEffect(() => {
        setTimeout(() => {
            setImageLoadEffect(true);
        }, 4000)
    }, []);

    return (
        <div className="rightSide-cotainer">
            <div className="rightSide-content">
                <div className="rightSide-image">
                    <img
                        className="imgTwoWhite" src="/img/portSix.jpg" alt="me foto" />
                    <img style={imageLoadEffect ? stylesTimers.loadImageEffect : stylesTimers.loadImageEffectBlock}
                        className="imgOneOrange" src="/img/portSixTwoNeon.jpg" alt="me foto" />
                </div>
            </div>
        </div>
    );
};

export default Portret;