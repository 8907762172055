import "./style_Word_P.css";

function WordP(): JSX.Element {

    return (
        <div className="botomSide-cotainer">
            <div className="botomSide-content">
                <h1>P</h1>
            </div>
        </div>
    );
};

export default WordP;