import React, { useRef } from "react";
import { Routes, Link, Route, BrowserRouter } from "react-router-dom";
import "./style_Slider.css";
import { Home, Contact, AboutMe } from "./SliderItems";
import { Type_For_RouterEffect } from "./types";

function Slider() {
  const [routerEffect, setRouterEffect] = React.useState<Type_For_RouterEffect>({
    home: false,
    aboutMe: false,
    contact: false,
  });

  // Definovanie useRef s typom HTMLAnchorElement
  const homeLinkRef = useRef<HTMLAnchorElement>(null);
  const aboutMeLinkRef = useRef<HTMLAnchorElement>(null);
  const contactLinkRef = useRef<HTMLAnchorElement>(null);

  const autoScroolFunction = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // Použitie useRef pre prístup k DOM elementom
    if (e.target === homeLinkRef.current) {
      homeLinkRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (e.target === aboutMeLinkRef.current) {
      aboutMeLinkRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (e.target === contactLinkRef.current) {
      contactLinkRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    setTimeout(() => {
      if (e.target === homeLinkRef.current) {
        setRouterEffect((current) => ({
          ...current,
          home: true,
          aboutMe: false,
          contact: false,
        }));
      } else if (e.target === aboutMeLinkRef.current) {
        setRouterEffect((current) => ({
          ...current,
          home: false,
          aboutMe: true,
          contact: false,
        }));
      } else if (e.target === contactLinkRef.current) {
        setRouterEffect((current) => ({
          ...current,
          home: false,
          aboutMe: false,
          contact: true,
        }));
      }
    }, 200);
  };

  return (
    <BrowserRouter>
      <div className="routes-container">
        <div className="toogle-link-container">
          <div className="link-block">
            <span></span>
            {/* Pridanie ref pre jednotlivé Link komponenty */}
            <Link
              ref={homeLinkRef}
              className="link home"
              onClick={autoScroolFunction}
              to="/"
            >
              Home
            </Link>
          </div>
          <div className="link-block">
            <span></span>
            <Link
              ref={aboutMeLinkRef}
              className="link aboutMe"
              key={"AboutMe"}
              onClick={autoScroolFunction}
              to="/aboutMe"  >
              About me
            </Link>
          </div>
          <div className="link-block">
            <span></span>
            <Link
              ref={contactLinkRef}
              className="link contact"
              onClick={autoScroolFunction}
              to="/contact">
              Contact
            </Link>
          </div>
        </div>

        <div className="routes-block">
          <Routes>
            <Route index element={<Home  />} />
            <Route path="aboutMe" element={<AboutMe routerEffectForItem={routerEffect} />} />
            <Route path="contact" element={<Contact routerEffectForItem={routerEffect} />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Slider;
