import { stylesTimers } from "../../services/timers";
import { Type_For_RouterEffect } from "../types";

type Props = {
    routerEffectForItem: Type_For_RouterEffect;
};

function Contact({ routerEffectForItem }: Props): JSX.Element {
    return (
        <div className="contact-container">
            <div className="contact-content">
                <div
                    className="block facebook"
                    onClick={() => { window.location.replace('https://m.facebook.com/polarFoxxx') }}
                    style={routerEffectForItem.contact ? stylesTimers.loadContactFacebookEffect : stylesTimers.loadContactFacebookEffectBlock}
                >
                    <div className="logo facebook-contact">
                        <img src="../../../../logos/Facebook-1.png" alt="logo" />
                    </div>
                    <div className="name contact">
                        <h1>FACEBOOK</h1>
                    </div>
                </div>
                <div
                    className="block email"
                    onClick={() => { window.location.replace("mailto:suchovsky.michal@gmail.com") }}
                    style={routerEffectForItem.contact ? stylesTimers.loadContactEmailEffect : stylesTimers.loadContactEmailEffectBlock}
                >
                    <div className="logo email-contact">
                        <img src="../../../../logos/The-loophole-in-Gmail-worries-its-users.jpg" alt="logo" />
                    </div>
                    <div className="name emaile">
                        <h1>EMAIL</h1>
                    </div>
                </div>
                <div
                    className="block linkedin"
                    onClick={() => { window.location.replace("https://www.linkedin.com/in/michal-suchovsky-304464238") }}
                    style={routerEffectForItem.contact ? stylesTimers.loadContactLinkedInEffect : stylesTimers.loadContactLinkedInEffectBlock}
                >
                    <div className="logo linkedin-contact">
                        <img src="../../../../logos/linked.jpg" alt="logo" />
                    </div>
                    <div className="name contact">
                        <h1>LINKEDIN</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
