import React from "react";

function Home(): JSX.Element {
    const indexRef = React.useRef(0);
    const oneBlockRef = React.useRef<null | HTMLDivElement>(null);
    const [style, setStyle] = React.useState({
        transform: "translateX()",
        transition: "1.5s"
    });

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (oneBlockRef.current) {
                const withClient = oneBlockRef.current.clientWidth;
                const motionWidth = -(indexRef.current * withClient)
                if (indexRef.current === 9) {
                    setStyle(current => ({ ...current, transform: `translateX(0px)`, transition: "none" }));
                    indexRef.current = 1
                } else {
                    setStyle(current => ({ ...current, transform: `translateX(${motionWidth}px)`, transition: "1.5s" }));
                    indexRef.current = indexRef.current + 1
                };
            };
        }, 3000);

        return (() =>
            clearInterval(interval))
    }, []);


    return (
        <div className="home-block">
            <div className="backgroundFont">
                <h1> FX </h1>
            </div>
            <div className='home-container'>
                <div
                    className="home-slider-box"
                    style={style} >
                    <div
                        className="home-slider sld"
                        ref={oneBlockRef}>
                        <h1> FRONTEND </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> CSS </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> HTML </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> JAVASCRIPT </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> TYPESCRIPT </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> REACT </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> GIT </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> NODE.js </h1>
                    </div>
                    <div className="home-slider sld">
                        <h1> FRONTEND </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home


