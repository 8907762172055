
export const stylesTimers = {
    /* velky nadpis */
    loadStyleEffect: {
        transform: "translateX(0)",
        opacity: "1",
        transition: "ease-out 1.5s"
    },
    loadStyleEffectBlockNone: {
        transform: "translateX(-120%)",
        opacity: "0"
    },

    /* podnadpis nadpis */
    loadStyleEffectLittle: {
        transform: "translateX(0)",
        opacity: "1",
        transition: "ease-out 2s"
    },
    loadStyleEffectLittleNone: {
        transform: "translateX(120%)",
        opacity: "0"
    },

    /* lavo vertikalny nadpis */
    loadStyleEffectVertikal: {
        top: "-3vw",
        opacity: "1",
        transition: "ease-out 1s"
    },
    loadStyleEffectVertikalBlockNone: {
        top: "-100%",
        opacity: "1"
    },

    /* obrazok nenon effect */
    loadImageEffect: {
        opacity: "1",
        transition: "1.4s"

    },
    loadImageEffectBlock: {
        opacity: "0",
    },

    /* aboutMe Effekt */
    loadAboutMEEffectImage: {
        right: "0",
        transition: "2s"

    },
    loadAboutMEEffectImageBlock: {
        right: "100%",

    },
    loadAboutMEEffectText: {
        left: "0",
        transition: "2s"
    },
    loadAboutMEEffectTextBlock: {
        left: "100%",
    },
    /* contact Effekt Facebook */
    loadContactFacebookEffect: {
        right: "0",
        transition: "2s"
    },
    loadContactFacebookEffectBlock: {
        right: "120%",
    },

    /* contact Effekt Email */
    loadContactEmailEffect: {
        left: "0",
        transition: "2s",
        transitionDelay: "0.5s",
    },
    loadContactEmailEffectBlock: {
        left: "120%",
    },

    /* contact Effekt LinkedIn */
    loadContactLinkedInEffect: {
        right: "0",
        transition: "2s",
        transitionDelay: "0.8s",
    },
    loadContactLinkedInEffectBlock: {
        right: "120%",
    },

}