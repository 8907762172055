import { stylesTimers } from "../../services/timers";
import { Type_For_RouterEffect } from "../types";

type Props = {
    routerEffectForItem: Type_For_RouterEffect;
};

function AboutMe({ routerEffectForItem }: Props): JSX.Element {
    return (
        <div className="aboutMe-block">
            <div className='about-container'>
                <div className="about-image"
                    style={routerEffectForItem.aboutMe ? stylesTimers.loadAboutMEEffectImage : stylesTimers.loadAboutMEEffectImageBlock}>
                    <img src="/img/imgOne.jpg" alt="my photos" />
                </div>
                <div className="about-text"
                    style={routerEffectForItem.aboutMe ? stylesTimers.loadAboutMEEffectText : stylesTimers.loadAboutMEEffectTextBlock}>
                    <div className="text-content">
                        <p>
                            Hi,My name is Michal, and I am 36 years old. I currently work as a specialist in the manufacturing department, focusing on CNC programming for various types of machines. My experience extends beyond manufacturing, as I have also worked in the technology department, where I gained insights into various aspects of technology and innovation.
                            In my spare time, I am actively involved in sports and programming. My primary focus is on creating websites and applications. I have extensive experience with React and TypeScript, which are my preferred frameworks for developing modern and efficient web solutions. Additionally, I am proficient in Node.js, allowing me to create robust APIs and backend solutions.
                            Thank you for visiting my website. If you are interested in collaborating or need more information, please feel free to contact me.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;

