import './App.css';
import { WordP, MotionTittle, Slider , Portret} from './modules';

function App(): JSX.Element {

  return (
    <div className="master-conatiner">
      <Portret />
      <MotionTittle />
      <Slider />
      <WordP />
    </div>
  );
};

export default App;


