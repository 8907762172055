
import React from "react";
import { stylesTimers } from "../services/timers";
import "./style_Motion_Tittle.css";

function MotionTittle(): JSX.Element {
    const [loadPageMotionEffect, setLoadPageMotionEffect] = React.useState(false);
    const [loadPageMotionEffectLittle, setLoadPageMotionEffectLittle] = React.useState(false);
    const [loadPageMotionEffectLeft, setLoadPageMotionEffectLeft] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setLoadPageMotionEffect(true);
        }, 1000);
        setTimeout(() => {
            setLoadPageMotionEffectLittle(true);
        }, 1500)
        setTimeout(() => {
            setLoadPageMotionEffectLeft(true);
        }, 2500)
    }, []);

    return (
        <div className="leftSide-cotainer">
            <div className="lefrtSide-content-right">
                <div className="lefrtSide-content-right-name">
                    <h4> portfolio </h4>
                </div>
            </div>
            <div className="lefrtSide-content-left">
                <div
                    className="lefrtSide-content-left-name"
                    style={loadPageMotionEffectLeft ? stylesTimers.loadStyleEffectVertikal : stylesTimers.loadStyleEffectVertikalBlockNone}>
                    <h4> portfolio </h4>
                </div>
            </div>
            <div className="lefrtSide-content-botton">
                <div className="lefrtSide-content-botton-content">
                    <div
                        className="leftSide-header"
                        style={loadPageMotionEffect ? stylesTimers.loadStyleEffect : stylesTimers.loadStyleEffectBlockNone}>
                        <span className="one"> Hel </span>
                        <span className="two"> lo...</span>
                    </div>
                    <div
                        className="leftSide-Litte-header"
                        style={loadPageMotionEffectLittle ? stylesTimers.loadStyleEffectLittle : stylesTimers.loadStyleEffectLittleNone}>
                        <h3> I am </h3>
                        <span> frontend </span>
                        <h3> developer </h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MotionTittle;